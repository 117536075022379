.EventCard {
  width: 32.3%;
  box-sizing: border-box;
  // max-width: 35%;
  // flex-grow: 1;
  align-self: stretch;
  height: unset;
  > div {
    height: 100%;
  }
}

@media screen and (max-width: 1400px) {
  .EventCard {
    width: 45%;
  }
}

@media screen and (max-width: 800px) {
  .EventCard {
    width: 100%;
    max-width: 100%;
  }
}