.AdminPage {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.SettingsMenuItem {
  color: var(--amplify-colors-font-primary);
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  &._active {
    background-color: var(--amplify-colors-background-secondary);
  }
}

.link {
  color: var(--amplify-colors-font-tertiary);
  text-decoration: none;
  &:hover {
      text-decoration: underline;
      cursor: pointer;
  }
}