.Footer {
    height: fit-content;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.Link {
    color: var(--amplify-colors-font-primary);
    text-decoration: none;
    text-align: center;
    &:hover {
        text-decoration: underline;
    }
}